import Vue from 'vue'
import App from './App.vue'

import Store from "./store"
import router from './router'
import mixin from "./mixins"
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.mixin(mixin)
Vue.use(VueMeta)
new Vue({
  render: h => h(App),
  router,
  store: Store
}).$mount('#app')
