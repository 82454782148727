<template>
  <div>
    <div id="menuContainer">
      <div>
        <router-link :to="{ name: 'Index' }">INDEX</router-link>
      </div>
      <div>
        <router-link :to="{ name: 'About' }">ABOUT</router-link>
      </div>
    </div>
    <div id="infoContainer">
      <div>{{general.mainTitle.toUpperCase() }}</div>
      <div>
        <a :href="'tel:'+general.num_tel" v-html="general.num_tel.toUpperCase()" />
      </div>
      <div style="margin-bottom:1em">
        <a :href="'mailto:'+general.contactEmail" v-html="general.contactEmail.toUpperCase()" />
      </div>
      <div>
        <a :href="general.instaLink">IG</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      general: "general/general",
    }),
  },
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;
}

#menuContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: $paddingAfterTitle $paddingMain $paddingMain $paddingMain;
  font-size: $big;
  line-height: 0.9em;
}

#infoContainer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: $small;
  padding: $paddingMain;
}

@media screen and (max-width: $breakPoint) {
  #infoContainer {
    font-size: 3rem;
  }
  #menuContainer,
  #infoContainer {
    padding-left: $paddingTop;

    padding-right: $paddingTop;
    line-height: normal;
  }
}
</style>