import axios from "axios"
import API_BASE from "../apiBase"

export default {

    namespaced: true,
    state: () => ({
        currProject: {},
        projects: [],
    }),
    mutations: {
        UPDATE_CURR_PROJECT(state, payload) {
            console.log(payload)
            state.currProject = payload;
            state.projects.forEach(p => {
                if (p.id == payload.id) {
                    p.photos = payload.photos
                    p.texts = payload.texts
                    console.log("updated project in list")
                }
            })
        },
        UPDATE_PROJECTS(state, payload) {
            state.projects = payload;
        }
    },
    actions: {
        async loadProjects({ state, commit }) {
            console.log("loading")
            return new Promise((resolve, reject) => {
                if (!state.projects.length) {
                    axios
                        .get(API_BASE+"/api/?querytype=projects")
                        .then(response => {
                            if ("error" in response.data) {
                                console.error(response.data.error)
                                reject(response.data.error)
                            }
                            else {
                                commit("UPDATE_PROJECTS", response.data.data)
                                resolve()
                            }
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
                else {
                    resolve()
                }
            })

        },
        async loadProject({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                commit("UPDATE_CURR_PROJECT", {})
                var project = {}; // eslint-disable-line no-unused-vars

                state.projects.forEach(p => {
                    if (p.slug == payload) {
                        project = p;
                    }
                })
                console.log(project.content)
                if (!("content" in project) || !("texts" in project)) {
                    console.log("making requestr")
                    var url = API_BASE+"/api/?querytype=project&id=" + payload
                    axios
                        .get(url)
                        .then(response => {
                            if ("error" in response.data) {
                                reject(response.data.error)
                            }
                            else {
                                commit("UPDATE_CURR_PROJECT", response.data.data)
                                resolve()
                            }
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
                else {
                    commit("UPDATE_CURR_PROJECT", project)
                    console.log("project retrieved from cache")
                    resolve()
                }
            })
        }

    },
    getters: {
        projects(state) {
            return state.projects;
        },
        project(state) {
            return state.currProject
        }
    }
}