<template>
  <div
    id="app"
    :class="{black : this.$route.name == 'Index' ||this.$route.name == 'Menu'|| this.$route.name == 'Home', mobileBlack: this.$route.name == 'Project'}"
  >
    <div class="mainTitle" v-if="$route.name != 'Project' && $route.name != null">
      <router-link
        :to="{ name: $route.name == 'Home'?'Menu':'Home' }"
      >{{ general.mainTitle.toUpperCase() }}</router-link>
    </div>
    <div
      class="plusNav"
      v-if="$route.name == 'Project' || $route.name == 'About' || $route.name == 'Index'"
      @click="navBack"
    >
      <cross />
    </div>
    <div ref="tester" id="tester"></div>
    <router-view :general="general" />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import cross from "@/components/cross.vue";
export default {
  name: "Main",
  metaInfo() {
    return {
      title: this.general.mainTitle,
    };
  },
  data() {
    return {
      lazyload: null,
    };
  },
  components: { cross },
  mounted() {
    window.addEventListener("resize", this.setWindowSize);
    document.body.addEventListener("mousemove", this.updateMouse);
    this.setWindowSize();
  },

  computed: {
    ...mapGetters({
      general: "general/general",
    }),
    ...mapState("display", ["prevRoute"]),
  },
  methods: {
    updateMouse(e) {
      this.$store.commit("display/SET_MOUSE", {
        x: e.clientX,
        y: e.clientY,
        target: e.target,
      });
    },
    setWindowSize() {
      this.$store.commit("display/SET_SCREEN", {
        w: window.innerWidth,
        h: window.innerHeight,
      });
      var style = window.getComputedStyle(this.$refs.tester);
      this.$store.commit("display/SET_TOUCH", style.width == "0px");
      this.$store.commit("display/SET_MOBILE", style.display == "none");
    },
    navBack() {
      if (this.$route.name == "Project") {
        this.$router.push({ name: "Index" });
      } else {
        this.$router.push({ name: "Menu" });
      }
    },
  },
  watch: {
    /* eslint-disable no-unused-vars */
    $route: {
      handler(to, from) {
        this.$nextTick(function () {
          this.lazyload.update();
        });
      },
    },

    /* eslint-enable no-unused-vars */
  },
};
</script>
<style src="@/assets/perfect-scrollbar.css">
</style>
<style lang="scss">
html,
body {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  font-size: $defaultFontSize;
}
html {
  background-color: white;
}

#app {
  font-family: "MediumLL", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  &.black {
    background-color: black;
    color: white;
  }
}
a {
  text-decoration: none;
  color: inherit;
}

.mainTitle,
.plusNav {
  position: fixed;
  font-size: $big;
  top: $paddingTop;
}

.mainTitle {
  left: $paddingMain;
  z-index: 150;
  a {
    color: inherit;
  }
}

.plusNav {
  right: $paddingMain;
  z-index: 150;
  transform: translateY(34%);
  cursor: pointer;
  // mix-blend-mode: difference;
  a {
    color: white !important;
  }
  svg {
    height: 3rem;
    width: auto;
    display: block;
  }
}
p {
  margin: 0px;
}

#tester {
  display: block;
  width: 0px;
}
@media screen and (max-width: $breakPoint) {
  #tester {
    display: none;
  }
  html,
  body {
    font-size: $mobileFontSize;
  }
  .mainTitle {
    left: $paddingTop;
  }

  .plusNav {
    right: $paddingTop;
    transform: translateY(0%);
    top: 20px;
    svg {
      height: 20px;
    }
  }
  #app.mobileBlack {
    background-color: black;
    color: white;
  }
}
@media not screen and (hover: none) {
  #tester {
    width: 1px;
  }
}
</style>
