export default {
    namespaced: true,
    state() {
        return {
            mouse: { x: 0, y: 0, target: null },
            screen: {
                w: 0,
                h: 0
            },
            mobile: false,
            touch: false,
            prevRoute: null
        }
    },
    mutations: {
        SET_MOUSE(state, payload) {
            state.mouse.x = payload.x;
            state.mouse.y = payload.y
            state.mouse.target = payload.target
        },
        SET_SCREEN(state, payload) {
            state.screen.w = payload.w;
            state.screen.h = payload.h;
        },
        SET_MOBILE(state, payload) {
            state.mobile = payload;
        },
        SET_TOUCH(state, payload) {
            state.touch = payload;
        },
        SET_PREVIOUS_ROUTE(state, payload) {
            console.log(payload)
            state.prevRoute = payload;
        }
    }
}