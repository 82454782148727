import API_BASE from "./apiBase"

import axios from "axios"
export default {

    namespaced: true,
    state: () => ({
        images: [],
        loaded: false
    }),
    mutations: {
        UPDATE_HOME(state, payload) {
            state.images = payload.images
            state.loaded = true
        }
    },
    actions: {
        async loadPage({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(API_BASE+"/api/?querytype=home")
                    .then(response => {
                        console.log(response)
                        if ("error" in response.data) {
                            reject(response.data.error)
                        }
                        else {
                            commit("UPDATE_HOME", response.data.data)
                            resolve()
                        }
                    })
            })

        },
    },
    getters: {
        home(state) {
            return state;
        }
    }
}