import LazyLoad from "vanilla-lazyload";
export default {
    data() {
        return {
            lazyload: null
        }
    },
    mounted() {
        this.lazyload = new LazyLoad({
            // Your custom settings go here
        });
    },
    methods: {
        breakLine(t) {
            return t.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        ondiv(mouse, div) {
            var foundDiv = false;
            var target = mouse.target;
            if (target != null) {
                while (!foundDiv && target.parentNode != null) {
                    if (div == target) {
                        foundDiv = true;
                    } else {
                        target = target.parentNode;
                    }
                }
            }

            return foundDiv;
        },
    }
}