
import API_BASE from "./apiBase"
import axios from "axios"
export default {
    namespaced: true,
    state: () => ({
        mainTitle: "Margaux Piette",
        instaLink: "",
        aboutText: "",
        contactEmail : "",
        num_tel: ""
    }),
    mutations: {
        UPDATE_GENERAL(state, payload) {
            state.mainTitle = payload.mainTitle;
            state.instaLink = payload.instaLink;
            state.aboutText = payload.aboutText;
            state.contactEmail = payload.contactEmail;
            state.num_tel = payload.num_tel;
        }
    },
    actions: {
        loadGeneral({ commit }) {
            axios
                .get(API_BASE+"/api/?querytype=general")
                .then(response => {
                    if ("error" in response.data) {
                        console.error(response.data.error)
                    }
                    else {
                        commit("UPDATE_GENERAL", response.data.data)
                    }
                })
        },
    },
    getters: {
        general(state) {
            return state;
        }
    }
}