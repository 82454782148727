import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from '../views/Projects.vue'
import Project from "@/views/Project"
import Menu from "@/views/Menu"

import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/menu/',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/index/',
    name: 'Index',
    component: Projects
  },
  {
    path: '/index/:project',
    name: 'Project',
    params: true,
    component: Project
  },
  {
    path: '/about/',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: (process.env.NODE_ENV !== 'production' ? "/margauxpiette" : ""),
  routes
})

function matchChild(name, to) {
  return !!to.matched.filter(e => e.name == name).length
}


router.beforeEach(async (to, from, next) => {
  if (!store.state.general.instaLink) {
    await store.dispatch("general/loadGeneral", to.params.project)
  }
  if (matchChild("Project", to)) {
    await store.dispatch("projects/loadProject", to.params.project)
  }
  else if (matchChild("Index", to)) {
    await store.dispatch("projects/loadProjects")
  }
  else if (matchChild("Home", to)) {
    await store.dispatch("home/loadPage")
  }
  next()
})

export default router
