<template>
  <div id="menuContainer">
    <div class="links" ref="linksContainer">
      <template v-for="(project, i) in projects">
        <div
          :key="project.id"
          class="menuLink"
          @mouseenter="setActiveProject(i)"
          @mouseleave="setActiveProject(-1)"
        >
          <router-link
            :to="{ name: 'Project', params: { project: project.slug } }"
          >{{ project.title.toUpperCase() }}</router-link>
        </div>
        <br :key="'br'+project.id" />
      </template>
    </div>
    <div class="images" v-if="!mobile">
      <div class="filler">
        <div v-for="(project, i) in projects" :key="project.id" class="photoContainer">
          <div :key="project.id" v-if="project.thumbnail" class="photo" :style="genStyleProject(i)">
            <ImageElement :img="project.thumbnail" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
import ImageElement from "@/components/Image.vue";
export default {
  name: "Home",
  components: { ImageElement },
  data() {
    return {
      activeProject: -1,
      currImage: 0,
      scrollArea: null,
    };
  },
  mounted() {
    setInterval(this.nextImage, 100);

    this.scrollArea = new PerfectScrollbar(this.$refs.linksContainer);
  },
  computed: {
    ...mapGetters({
      _projects: "projects/projects",
    }),
    ...mapState("display", ["mobile"]),
    projects() {
      var p = [];
      this._projects.forEach((pro) => {
        p.push({
          ...pro,
          photos: pro.content.filter((c) => {
            return c.type == "photo";
          }),
          numPhotos: pro.content.filter((c) => {
            return c.type == "photo";
          }).length,
        });
      });
      console.log(p);
      return p;
    },
  },
  methods: {
    setActiveProject(n) {
      this.activeProject = n;
    },
    genStyleProject(n) {
      return {
        visibility: n == this.activeProject ? "visible" : "hidden",
        // visibility: "visible",
      };
    },
    genStyleImage(n, l) {
      return {
        display: n == this.currImage % l ? "block" : "none",
        // display: "block",
      };
    },
    nextImage() {
      this.currImage++;
    },
  },
};
</script>

<style lang="scss" scoped>
#menuContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: $paddingAfterTitle $paddingMain 0px $paddingMain;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
}
.menuLink {
  display: inline-block;
}
a {
  color: inherit;
  text-decoration: none;
  transition: color 200ms;
  &:hover {
  }
}
.links {
  // text-align: center;
  overflow-x: visible;
  .menuLink {
    line-height: 0.9em;
    font-size: $big;
  }
}
.images {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  // box-sizing: border-box;
  z-index: -10;
  // overflow: hidden;
  // padding: 2 * $paddingMain;
  pointer-events: none;
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
}
.photo {
  position: relative;
  padding: 0px 100px;
  box-sizing: border-box;
}
.photoContainer,
.photo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

img {
  width: 100%;
  height: 100%;
}
.filler {
  position: relative;
  height: 100%;
}

img {
  object-fit: contain;
}

@media screen and (max-width: $breakPoint) {
  #menuContainer {
    grid-template-columns: 1fr;
    padding-left: $paddingTop;
  }
  .links .menuLink {
    line-height: normal;
  }
}
</style>
