<template>
  <div id="projectContainer" @click="changeSlide" ref="projectContainer">
    <template v-for="(content, i) in project.content">
      <div
        v-if="content.type=='photo'"
        :key="content.id+i"
        :class="{photoContainer:true,  contentBlock:true, visible:i==currentSlide}"
      >
        <div class="cont">
          <ImageElement :img="content.image" />
        </div>
      </div>
      <div
        v-else
        :key="content.id+i"
        v-html="breakLine(content.text)"
        :class="{textContainer:true,  contentBlock:true, visible:i==currentSlide}"
      />
    </template>

    <div
      key="lastSlide"
      :class="{multiTextContainer:true,  contentBlock:true, visible:project.content.length==currentSlide}"
    >
      <div class="titleContainer">{{project.title.toUpperCase()}}</div>
      <div class="textsContainer">
        <div>{{project.texts[0]}}</div>
        <div>{{project.texts[1]}}</div>
        <div>{{project.texts[2]}}</div>
      </div>
    </div>
    <div ref="arrow" class="arrow" :style="genArrowPosition">{{direction}}</div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ImageElement from "@/components/Image";
export default {
  name: "Project",
  components: { ImageElement },
  data() {
    return {
      currentSlide: 0,
    };
  },
  mounted() {},
  metaInfo() {
    return {
      title: this.general.mainTitle + " - " + this.project.title,
      description: this.project.seo_description,
      meta: [{ name: "description", content: this.project.seo_description }],
    };
  },
  computed: {
    ...mapGetters({
      project: "projects/project",
    }),
    ...mapState("display", ["mouse", "screen", "touch"]),
    ...mapGetters({
      general: "general/general",
    }),
    direction() {
      if (this.mouse.x < this.screen.w / 2) {
        return "left";
      } else {
        return "right";
      }
    },
    genArrowPosition() {
      var disp = "none";
      if (this.ondiv(this.mouse, this.$refs.projectContainer) && !this.touch) {
        disp = "block";
      }
      return {
        top: this.mouse.y + "px",
        left: this.mouse.x + "px",
        transform:
          this.mouse.x < this.screen.w / 2
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        display: disp,
      };
    },
  },
  methods: {
    changeSlide() {
      if (this.mouse.x < this.screen.w / 2) {
        this.currentSlide--;
      } else {
        this.currentSlide++;
      }
      this.currentSlide =
        (this.currentSlide + this.project.content.length + 1) %
        (this.project.content.length + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#projectContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: white;
  cursor: none;
}

.contentBlock {
  padding: $paddingTop;
  position: absolute;
  display: none;
  // transition: opacity 500ms;
  &.visible {
    display: block;
  }
}
.photoContainer {
  padding: $paddingMain 100px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .cont {
    width: 100%;
    height: 100%;
    position: relative;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.textContainer {
  font-size: $big;
}

.multiTextContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.titleContainer,
.textsContainer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
.titleContainer {
  font-size: $big;
}
.textsContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 2 * $paddingTop);
  display: flex;
  padding: 0px 4px;
  box-sizing: border-box;
  div {
    flex-basis: 33.33%;
    text-align: center;
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
}
.lastSlide {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.arrow {
  position: absolute;
  z-index: 300;
  pointer-events: none;
  mix-blend-mode: difference;
  color: white;
  font-size: 13px;
}
@media screen and (max-width: $breakPoint) {
  #projectContainer {
    background-color: black;
  }
  .photoContainer {
    padding: 20px 0px;
  }
  .multiTextContainer {
    // padding: 30px;
  }
  .textsContainer {
    position: absolute;
    top: unset;
    bottom: $paddingTop;
    width: calc(100% - 2 * $paddingTop);
    transform: unset;
    flex-direction: column;
    font-size: 4rem;
    div {
      flex-basis: 100%;
      text-align: left;
      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: left;
      }
    }
  }
}
</style>