import Vue from 'vue'
import Vuex from 'vuex'

import Projects from "./projects"
import Home from "./home.js"
import General from "./general"
import Display from "./display"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  modules: {
    projects: Projects,
    home:Home,
    general : General,
    display:Display
  }
})

export default store;